<template>
  <div>
    <v-container>
      <v-row class="mx-md-4">
        <!-- begin::search text field -->
        <v-col class="" cols="12">
          <v-text-field
            filled
            rounded
            prepend-icon=""
            prepend-inner-icon="mdi-magnify"
            placeholder="Buscar estudiante"
            v-model="students.search"
            clearable
          ></v-text-field>
        </v-col>
        <!-- end::search text field -->

        <v-col cols="12">
          <v-data-table
            v-if="searchNotEmpty"
            :headers="students.headers"
            :items="sortedStudents"
            :search="students.search"
            :items-per-page="2"
            :loading="students.isLoading"
            :mobile-breakpoint="800"
            no-results-text="No se encontraron estudiantes con el nombre o código de carnet ingresado en la búsqueda."
            dense
          >
            <!-- student picture -->
            <template v-slot:[`item.photo`]="{ item }">
              <template>
                <v-avatar size="50" class="my-2">
                  <v-img :src="item.photo"></v-img>
                </v-avatar>
              </template>
            </template>

            <!-- Student name -->
            <template v-slot:[`item.first_name`]="{ item }">
              <template>
                <p class="mb-0 font-weight-medium text-body-1">
                  {{ item.first_name }}
                </p>
              </template>
            </template>
            <template v-slot:[`item.last_name`]="{ item }">
              <template>
                <p class="mb-0 font-weight-medium text-body-1">
                  {{ item.last_name }}
                </p>
              </template>
            </template>

            <!-- Student code -->
            <template v-slot:[`item.code`]="{ item }">
              <template>
                <p class="mb-0 font-weight-bold text-body-1">
                  {{ item.code }}
                  <v-icon
                    color="grey darken-1"
                    small
                    class="ml-1"
                    @click="copyToClipboard(item.code)"
                    >mdi-content-copy</v-icon
                  >
                </p>
              </template>
            </template>

            <!-- begin:: Late arrival check record-->
            <template v-slot:[`item.actions`]="{ item }">
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <v-checkbox
                       v-bind="attrs"
                      v-on="on"
                      v-model="studentsSelectedForCodeAssigning"
                      :value="item"
                    >
                    </v-checkbox> -->

                    <v-btn
                      :class="`mx-2 ${
                        isStudentInList(item).themeColor
                      } lighten-4`"
                      fab
                      dark
                      small
                      text
                      @click="addOrDeleteStudentToList(item)"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark :color="isStudentInList(item).themeColor">
                        mdi-{{ isStudentInList(item).icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ isStudentInList(item).tooltipText }}</span>
                </v-tooltip>
              </template>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- begin::selected student card -->
      <v-card v-if="isCardVisible" class="mx-1 mx-lg-7 my-5 elevation-0">
        <v-row class="d-flex justify-center align-start px-0">
          <!-- left -->
          <v-col cols="12" md="6" lg="6" xl="5">
            <!-- <v-col cols="12" md="8" lg="10" xl="6"> -->
            <v-card class="elevation-0 rounded-lg pa-3">
              <v-row class="d-flex justify-center">
                <v-col cols="12">
                  <p class="text-h6 font-weight-bold text-center mb-0">
                    Estudiantes seleccionados:
                  </p>
                </v-col>

                <v-col cols="12">
                  <v-list class="student-list">
                    <v-list-item
                      v-for="(
                        student, index
                      ) in studentsSelectedForCodeAssigning"
                      :key="index"
                      class="rounded-lg"
                    >
                      <v-row class="d-flex align-center">
                        <v-col
                          cols="12"
                          sm="10"
                          class="d-flex flex-column flex-sm-row pb-0 pb-sm-2"
                        >
                          <v-list-item-avatar class="mx-0 px-0 mr-sm-3">
                            <v-img :src="student.photo"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content class="pb-0">
                            <p
                              class="font-weight-bold text-body-1 mb-0 text-center text-sm-left"
                            >
                              {{ student.first_name }} <br />
                              {{ student.last_name }}
                            </p>

                            <p
                              class="font-weight-medium text--secondary text-center text-sm-left"
                            >
                              {{ student.code }} - {{ student.grade }}
                            </p>
                          </v-list-item-content>
                        </v-col>
                        <v-col
                          class="d-flex align-center justify-sm-end flex-column mt-n4 mt-sm-0 mb-7 mb-sm-0"
                          cols="12"
                          sm="2"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :class="` red lighten-4`"
                                fab
                                dark
                                small
                                text
                                color="primary"
                                @click="showMdlConfirmDeleteModal(student)"
                              >
                                <v-icon dark color="red"> mdi-delete </v-icon>
                              </v-btn>
                            </template>
                            <span>Remover de la lista</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- --------------------------------------------------------- -->

          <!-- right -->
          <v-col cols="12" md="6" lg="6" xl="7" class="">
            <!-- <v-col cols="12" md="8" lg="10" xl="6" class=""> -->

            <v-card outlined class="elevation-0 rounded-lg pa-3">
              <v-scroll-x-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <v-row
                  v-if="!aCodeHasBeenSelectedFromModal"
                  class="d-flex justify-center flex-row"
                >
                  <v-col cols="10" class="d-flex flex-column align-center">
                    <v-avatar size="70" color="blue lighten-4">
                      <v-icon color="blue">
                        mdi-card-account-details-outline
                      </v-icon>
                    </v-avatar>
                    <p class="my-2 text-center font-weight-medium text-body-1">
                      Seleccione el código que desea aplicar a los estudiantes
                      seleccionados
                    </p>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      depressed
                      color="primary"
                      @click="showMdlSelectCode()"
                    >
                      Seleccionar código
                    </v-btn>
                  </v-col>
                </v-row>
              </v-scroll-x-reverse-transition>
              <v-scroll-x-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <v-row v-if="aCodeHasBeenSelectedFromModal">
                  <v-col cols="12">
                    <p class="text-h6 font-weight-bold text-center mb-0">
                      Código seleccionado:
                    </p>
                    <v-list-item-group v-model="selectedCodeList">
                      <v-list class="v-list">
                        <v-list-item
                          @click="showMdlSelectCode()"
                          class="rounded-lg"
                        >
                          <v-list-item-content class="pb-0">
                            <v-list-item-subtitle class="mb-1">
                              <v-chip
                                :color="`${
                                  setCodeTypeTheme(
                                    selectedCodeFromModal.CodeType?.name
                                  ).color
                                } lighten-4 font-weight-medium`"
                                class="mt-1"
                              >
                                <p
                                  :class="`font-weight-medium ${
                                    setCodeTypeTheme(
                                      selectedCodeFromModal.CodeType?.name
                                    ).color
                                  }--text mb-0`"
                                >
                                  Código
                                  <span
                                    :class="`text-lowercase font-weight-bold ${
                                      setCodeTypeTheme(
                                        selectedCodeFromModal.CodeType?.name
                                      ).color
                                    }--text`"
                                    >{{
                                      selectedCodeFromModal.CodeType?.name
                                    }}</span
                                  >
                                </p>
                              </v-chip>
                            </v-list-item-subtitle>

                            <p class="font-weight-bold my-1 ml-3 pr-2">
                              {{ selectedCodeFromModal.name }}
                            </p>

                            <p
                              class="font-weight-medium text--secondary ml-3 pr-3"
                            >
                              {{ selectedCodeFromModal.description }}
                            </p>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-list-item-group>
                    <v-row class="mt-2">
                      <v-col cols="12" class="pb-0">
                        <form-group
                          name="Observación"
                          :validator="$v.observation"
                        >
                          <v-textarea
                            counter
                            filled
                            clearable
                            rounded
                            auto-grow
                            rows="2"
                            slot-scope="{ attrs }"
                            v-bind="attrs"
                            label="Observación (opcional)"
                            placeholder="Ej.: El estudiante..."
                            v-model.trim="observation"
                            @blur="$v.observation.$touch()"
                            @paste="handlePaste($event)"
                            @keyup.enter="handleKeyUpMotive()"
                          ></v-textarea>
                        </form-group>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          :class="`${
                            setCodeTypeTheme(
                              selectedCodeFromModal.CodeType?.name
                            ).color
                          } lighten-4 mb-2`"
                          @click="assignedSelectedCodeToSelectedStudents()"
                        >
                          <v-icon
                            left
                            :color="`${
                              setCodeTypeTheme(
                                selectedCodeFromModal.CodeType?.name
                              ).color
                            }`"
                            >mdi-paperclip-plus</v-icon
                          >
                          <span
                            :class="`${
                              setCodeTypeTheme(
                                selectedCodeFromModal.CodeType?.name
                              ).color
                            }--text font-weight-bold d-none d-sm-inline`"
                            >Añadir código al expediente</span
                          >
                          <span
                            :class="`${
                              setCodeTypeTheme(
                                selectedCodeFromModal.CodeType?.name
                              ).color
                            }--text font-weight-bold d-sm-none`"
                            >Asignar código</span
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-scroll-x-reverse-transition>
            </v-card>
          </v-col>
          <!-- --------------------------------------------------------- -->
        </v-row>
      </v-card>
      <!-- end::selected student card -->

      <!-- begin::fallback if there isn't any student selected -->
      <div v-else class="my-15">
        <v-scroll-x-reverse-transition>
          <v-row>
            <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
              <div class="mx-auto mb-4 pa-8 rounded-circle red lighten-4">
                <v-icon large color="red">mdi-account-group-outline</v-icon>
              </div>
              <div>
                <p class="text-center font-weight-bold text-h6 mb-1">
                  Seleccione un estudiante o ingrese un código de carnet
                </p>
                <p class="text-center font-weight-medium text-body-1">
                  Al seleccionar un estudiante o ingresar un código de carnet,
                  podrá añadirlo a la lista de estudiantes a los que desea
                  asignar un código.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-scroll-x-reverse-transition>
      </div>
      <!-- end::fallback if there isn't any student selected -->

      <SelectCodeModal
        ref="mdlSelectCode"
        colorTheme="primary"
        :codes="codes.data"
        :codeTypes="codeTypes"
        @codeSelected="setSelectedCodeDetails($event)"
      ></SelectCodeModal>

      <DeleteSelectedStudentConfirmationModal
        ref="mdlRemoveStudentFromList"
        :itemToDelete="studentToRemove"
        @confirmRemove="addOrDeleteStudentToList($event)"
      ></DeleteSelectedStudentConfirmationModal>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { minLength, maxLength, regex } from "vuelidate/lib/validators";
import SelectCodeModal from "@/components/modals/codes/SelectCodeModal.vue";
import DeleteSelectedStudentConfirmationModal from "@/components/modals/lateness/DeleteSelectedStudentConfirmationModal.vue";

export default {
  name: "AssignCodeToStudentCard",

  props: {
    students: {
      type: Object,
      default: () => {},
    },
    codeTypes: {
      type: Object,
      default: () => {},
    },
    codePlaces: {
      type: Object,
      default: () => {},
    },
    codes: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    SelectCodeModal,
    DeleteSelectedStudentConfirmationModal,
  },

  data() {
    return {
      OUTSIDE_CLASSROOM_CODEPLACE_UUID: "b7a6f457-4503-4439-9742-6690d94fda67",
      observation: "",
      selectedItem: null,
      selectedCodeList: null,
      studentToRemove: {},
      studentsSelectedForCodeAssigning: [],
      selectedCodeFromModal: {},
      onlyOneStudent: true,
      isCardVisible: false,
    };
  },

  watch: {
    studentsSelectedForCodeAssigning() {
      if (this.studentsSelectedForCodeAssigning.length > 0) {
        //request functions
        this.isCardVisible = true;
        this.students.search = null;
        return;
      }
      this.isCardVisible = false;
    },

    observation(newVal) {
      if (!!this.observation) {
        this.observation = newVal.replace(/ {3,}/g, "  ");
      }
    },
  },

  methods: {
    assignedSelectedCodeToSelectedStudents() {
      //validate all data before creating the body of the request

      let observation = this.observation == "" ? "" : this.observation;

      if (!this.$v.observation.$error/* && this.validateRegex*/) {
        let selectedStudentsUUIDs = [];

        for (
          let index = 0;
          index < this.studentsSelectedForCodeAssigning.length;
          index++
        ) {
          const student = this.studentsSelectedForCodeAssigning[index];
          selectedStudentsUUIDs.push(student.uuid);
        }

        const codeAboutToBeAssignRequestBody = {
          selected_students: selectedStudentsUUIDs,
          student_class_id: null,
          code_id: this.selectedCodeFromModal.id,
          observation: observation,
          id_user: this.currentUserPersonalInfo.id_user,
          code_place_id: this.codePlaces.data.find(
            (codePlace) => codePlace.name === "Ex-aula"
          )?.id,
        };

        //making request
        this.$emit("assignCodesToStudent", codeAboutToBeAssignRequestBody);

        this.resetCardState();

        return;
      }

      this.fireToast({
        icon: "error",
        title: `Hay datos invalidos, por favor intente de nuevo`,
      });
    },

    showMdlConfirmDeleteModal(student) {
      this.studentToRemove = student;
      this.$refs.mdlRemoveStudentFromList.toggleModal();
    },

    resetCardState() {
      this.isCardVisible = false;
      this.observation = "";
      this.selectedItem = null;
      this.studentsSelectedForCodeAssigning = [];
      this.selectedCodeFromModal = {};
      this.selectedCodeList = null;
    },

    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.observation = this.observation + paste;
    },

    handleKeyUpMotive() {
      this.$v.observation.$touch();
      if (!this.$v.observation.$invalid) {
        //la observación es válida, entonces:
      }
    },

    addOrDeleteStudentToList(student) {
      if (this.studentsSelectedForCodeAssigning.includes(student)) {
        // Object already exists in the array, so remove it
        this.studentsSelectedForCodeAssigning.splice(
          this.studentsSelectedForCodeAssigning.indexOf(student),
          1
        );
      } else {
        // Object doesn't exist in the array, so add it
        this.studentsSelectedForCodeAssigning.push(student);
      }
    },

    isStudentInList(student) {
      if (this.studentsSelectedForCodeAssigning.includes(student)) {
        return {
          themeColor: "deep-orange",
          icon: "checkbox-marked",
          tooltipText: "Quitar de la lista",
        };
      } else {
        return {
          themeColor: "blue",
          icon: "crop-square",
          tooltipText: "Añadir a la lista",
        };
      }
    },

    setSelectedCodeDetails(code) {
      this.selectedCodeFromModal = code;
      this.selectedCodeList = null;
    },

    showMdlSelectCode() {
      this.$refs.mdlSelectCode.toggleModal();
    },

    customFilter(item, queryText, itemText) {
      const first_name = item.first_name.toLowerCase();
      const last_name = item.last_name.toLowerCase();
      const full_name = item.full_name.toLowerCase();
      const code = `${item.code}`;

      const searchText = queryText.toLowerCase();

      return (
        first_name.indexOf(searchText) > -1 ||
        code.indexOf(searchText) > -1 ||
        last_name.indexOf(searchText) > -1 ||
        full_name.indexOf(searchText) > -1
      );
    },

    setCodeToStudent() {
      this.isLoadingStudentArrivalDetails = true;
      //Is being saved in a array because the endpoint uses the "bulkInsert" property; and it requires an array as a parameter
    },

    setCodeTypeTheme(codeTypeName) {
      let codeTypeSchemas = {
        Positivo: {
          color: "green",
          icon: "star",
        },
        Leve: {
          color: "orange",
          icon: "star-minus",
        },
        Grave: {
          color: "red",
          icon: "star-minus",
        },
        "Muy grave": {
          color: "purple",
          icon: "star-minus",
        },
      };

      let defaultSchema = {
        color: "grey",
        icon: "star-minus",
      };

      if (codeTypeSchemas.hasOwnProperty(codeTypeName)) {
        return codeTypeSchemas[codeTypeName];
      } else {
        return defaultSchema;
      }
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    aCodeHasBeenSelectedFromModal() {
      return Object.keys(this.selectedCodeFromModal).length != 0;
    },

    searchNotEmpty() {
      return !!this.students.search;
    },

    sortedStudents() {
      return this.students.data.sort((a, b) => {
        const aIsSelected = this.studentsSelectedForCodeAssigning.includes(a);
        const bIsSelected = this.studentsSelectedForCodeAssigning.includes(b);

        if (aIsSelected && !bIsSelected) {
          return -1;
        }
        if (!aIsSelected && bIsSelected) {
          return 1;
        }
        return 0;
      });
    },

    validateRegex() {
      let observation = this.observation == "" ? "" : this.observation;
      let regexPattern = /^[a-zA-Z\d-_.,;ñÑáéíóúÁÉÍÓÚ\s]+$/i;

      if (observation == null) {
        return true;
      } else {
        return observation != null && regexPattern.test(observation);
      }
    },
  },

  validations: {
    observation: {  },
  },
};
</script>

<style>
.student-list {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1 !important;
  max-height: 65vh;
  overflow-y: auto;
}
</style>
