<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Asignación de código ex-aula
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui podrá añadir un código al expediente del estudiante
              buscandolo en la barra de busqueda y seleccionandolo en la tabla
              de resultados.</span
            >
          </h3>
        </div>
      </div>

      <!-- begin::autocomplete select for student -->
      <div>
        <AssignCodeToStudentCard
          :students="students"
          :codes="codes"
          :codeTypes="codeTypes"
          :codePlaces="codes.places"
          @assignCodesToStudent="assignCodesToSelectedStudents($event)"
        ></AssignCodeToStudentCard>
      </div>
      <!-- end::autocomplete select for student -->
    </div>
    <!-- end::Card -->
  </div>
</template>

<script>
import latenessRepository from "@/repositories/latenessRepository";
import { mapGetters } from "vuex";
import codeRepository from "@/repositories/codeRepository";
import codeTypeRepository from "@/repositories/codeTypeRepository";
import codePlaceRepository from "@/repositories/codePlaceRepository";
import AssignCodeToStudentCard from "@/components/elements/code/AssignCodeToStudent.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "AssignCode",

  components: {
    AssignCodeToStudentCard,
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Asignación códigos ex-aula", route: "assign_code" },
    ]);
    this.getStudents(this.today);
    this.getCodes();
    this.getCodeTypes();
    this.getCodePlaces();
  },

  data() {
    return {
      students: {
        data: [],
        search: "",
        isLoading: false,
        filters: {},
        headers: [
          {
            text: "Foto",
            value: "photo",
            align: "center",
            sortable: false,
            filterable: false,
          },
          {
            text: "Nombre",
            value: "first_name",
            align: "center",
            filterable: true,
          },
          {
            text: "Apellido",
            value: "last_name",
            align: "center",
            filterable: true,
          },
          { text: "Código", value: "code", align: "center", filterable: true },
          { text: "Grado", value: "grade", align: "center", filterable: false },
          {
            text: "Seleccionar",
            value: "actions",
            sortable: false,
            align: "center",
            width: 25,
            filterable: false,
          },
        ],
      },

      codeTypes: {
        data: [],
        isLoading: false,
      },

      codes: {
        data: [],
        isLoading: false,
        places: {
          data: [],
          isLoading: false,
        },
      },
    };
  },

  methods: {
    getStudents(date) {
      this.students.data = [];
      this.students.isLoading = true;
      latenessRepository
        .studentListWithLateArrivalStatus(date)
        .then(({ data }) => {
          this.students.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.students.isLoading = false;
        });
    },

    getCodes() {
      this.codes.data = [];
      this.codes.isLoading = true;
      codeRepository
        .getAllEnabledCodes()
        .then(({ data }) => {
          this.codes.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.codes.isLoading = false;
        });
    },

    getCodeTypes() {
      codeTypeRepository
        .getAllCodeTypes()
        .then(({ data }) => {
          this.codeTypes.data = data.data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: `No ha sido posible cargar los datos desde el servidor`,
          });
        });
    },

    getCodePlaces() {
      this.codes.places.data = [];
      this.codes.places.isLoading = true;
      codePlaceRepository
        .getAllCodePlaces()
        .then(({ data }) => {
          this.codes.places.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.codes.places.isLoading = false;
        });
    },

    assignCodesToSelectedStudents(payload) {
      codeRepository
        .assignCodeToStudent(payload)
        .then(() => {
          this.fireToast({
            icon: "success",
            title: "Se asignó el código correctamente",
          });
          this.students.search = null;
        })
        .catch((response) => {
          this.fireToast({
            icon: "error",
            title: "No se pudo registrar el código en el expediente",
          });
        })
        .finally(() => {});
    },
  },

  computed: {
    today() {
      //includes time
      let date = new Date();
      return date.toISOString();
    },
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>
