<template>
  <v-dialog
    v-model="modalState"
    transition="scale-transition"
    max-width="400"
    persistent
  >
    <template v-slot:default="modalState">
      <v-card class="pb-2">
        <v-container class="text-h5 text-center">
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center mb-5 rounded-lg">
                <div
                  class="card-icon-container red lighten-4 d-flex align-center justify-center rounded-circle pa-5 mx-auto"
                >
                  <v-icon color="red" large
                    >mdi-content-save-alert-outline</v-icon
                  >
                </div>
              </div>
            </v-col>
          </v-row>

          <p class="mb-0 mx-auto font-weight-bold text-h6 mx-3">
            ¿Está seguro que desea eliminar este estudiante de la lista?
          </p>
        </v-container>

        <v-card-text>
          <p class="text-body-1 text-center">
            Si quisiera volver a añadirlo, deberá volver a buscarlo en la lista.
          </p>
        </v-card-text>

        <v-card-actions class="action-buttons-container">
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-4"
            text
            @click="modalState.value = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="red darken-1 white--text update-btn"
            @click="confirmData(itemToDelete)"
          >
            Sí, eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteSelectedStudentConfirmationModal",
  data() {
    return {
      modalState: false,
    };
  },
  props: {
    itemToDelete: {
      type: [String, Number, Object, Array],
    },
  },

  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },
    confirmData() {
      this.$emit("confirmRemove", this.itemToDelete);
      this.toggleModal();
    },
  },
};
</script>

<style>
.card-icon-container {
  aspect-ratio: 1/1;
  height: 100%;
  min-height: 100px !important;
}
@media (max-width: 350px) {
  .action-buttons-container {
    flex-direction: column !important;
  }
  .update-btn {
    margin-bottom: 1em !important;
  }
  .fallback-alert {
    display: none !important;
  }
}
</style>
